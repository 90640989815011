import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import apiFetch from '@/js/utils/api';

const API_BASE_URL = 'https://staging.nimba.dev'; // Replace with your actual API base URL

const ManageOrganizations = () => {
  const { t } = useTranslation();
  const [organizations, setOrganizations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [name, setName] = useState('');
  const [login, setLogin] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    fetchOrganizations();
  }, [dispatch]);

  const fetchOrganizations = async () => {
    try {
      setLoading(true);
      const url = `${API_BASE_URL}/api/user/organizations`;
      const data = await apiFetch({ url, dispatch });
      console.log('orgs', data);
      setOrganizations(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const addOrganization = async (event) => {
    event.preventDefault();
    if (!name || !login) {
      setError('Name and Login are required.');
      return;
    }

    try {
      const url = `${API_BASE_URL}/api/organizations/`;
      await apiFetch({
        url,
        dispatch,
        opts: {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ name, login }),
        },
      });

      setName('');
      setLogin('');

       // Redirect to the GitHub app installation URL
      window.location.href = 'https://github.com/apps/nimba-dev';
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <>
      <div className="slds-text-heading_large slds-m-bottom_small">
        {t('Manage Organizations')}
      </div>
      {!loading && !error && organizations.length > 0 &&
      <div className="slds-text-heading_medium slds-m-bottom_small">
        {t('Current Organizations:')}
        </div>}
      {loading && <div>Loading...</div>}
      {error && <div>Error: {error}</div>}
      {!loading && !error && (
        <ul>
          {organizations.map((org) => (
            <li key={org.id}>{org.name}</li>
          ))}
        </ul>
      )}
      <div className="slds-text-heading_medium slds-m-bottom_small">
        {t('Add Organizations:')}
        <div className="slds-m-bottom_medium slds-text-body_regular">
        <Trans i18nKey="addOrganization">
          Please enter the name and username (e.g. "Nimba-Solutions" from https://github.com/Nimba-Solutions/) for the organization you would like to add. You will then be redirected to install our Github app on your org! After installation, resync your orgs in the "Create Project" page.

        </Trans>
      </div>
      <form onSubmit={addOrganization}>
        <div>
          <label htmlFor="name">Organization Name: </label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="login">Organization's "username" on Github: </label>
          <input
            type="text"
            id="login"
            value={login}
            onChange={(e) => setLogin(e.target.value)}
          />
        </div>
        <button type="submit">{t('Submit')}</button>
      </form>
      </div>
    </>
  );
};

export default ManageOrganizations;
