import Button from '@salesforce/design-system-react/components/button';
import Icon from '@salesforce/design-system-react/components/icon';
import { Location } from 'history';
import cookies from 'js-cookie';
import React, { ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { StaticContext } from 'react-router';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';

import branchingOrgsFG from '@/img/branching-graphic.svg';

import { selectUserState } from '@/js/store/user/selectors';
import routes from '@/js/utils/routes';

interface Props
  extends RouteComponentProps<
    { [key: string]: any },
    StaticContext,
    { from?: Location }
  > {
  id?: string;
  label?: string | ReactElement;
  from?: { pathname?: string };
}

export const LoginButton = withRouter(
  ({ id = 'login', label, from = {}, location }: Props) => {
    const { t } = useTranslation();

    const action = window.api_urls.github_login?.();
    let { pathname } = location.state?.from || from;
    pathname = pathname || window.location.pathname;

    return (
      /* POSTing instead of redirecting to the login endpoint is more secure */
      <form action={action} method="POST">
        <input
          type="hidden"
          name="csrfmiddlewaretoken"
          value={cookies.get('csrftoken')}
        />
        <input
          type="hidden"
          name="next"
          value={pathname}
          data-testid="gh-login-next"
        />
        <Button
          id={id}
          type="submit"
          label={label === undefined ? t('Log In With GitHub') : label}
          variant="brand"
          disabled={!action}
        />
      </form>
    );
  },
);

const Login = () => {
  const { t } = useTranslation();
  const user = useSelector(selectUserState);

  return user ? (
    <Redirect to={routes.home()} />
  ) : (
    <div>
      <style>
        {`
          body {
            margin: 0;
            padding: 0;
            min-height: 100vh;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            font-family: Calibri;
          }
          
          h1 {
            font-size: clamp(1rem, 3vw, 3rem);
            margin-bottom: 0.5rem;
            text-align: center;
          }
          
          h2 {
            font-size: clamp(1rem, 2vw, 2.5rem);
            margin-bottom: 2rem;
            text-align: center;
          }
          
          p {
            font-size: clamp(1rem, 1.5vw, 2rem);
            text-align: center;
          }
          
          .uvp-list {
            font-size: clamp(1rem, 1vw, 2rem);
            text-align: center;
          }
          
          ul {
            display: inline-block;
            text-align: left;
          }
          .footer {
            position: fixed;
            bottom: 0;
            width: 100%;
            background-color: #f0f0f0;
            padding: 10px;
            text-align: center;
          }
        `}
      </style>
      <body>
        <div>
        <img
          src={branchingOrgsFG}
          alt="branching-orgs"
          style={{
            maxWidth: 'clamp(200px, 100%, 1200px)',
            maxHeight: '40vh',
            display: 'block',
            margin: '0 auto'
          }}
          
        />
        </div>
        <br/>
        <div class="normal">
          <h1>An easier way to Salesforce</h1>
          <h2>With nimba.dev, you can:</h2>
          <div class="uvp-list">
            <ul>
              <li>Create Dev Orgs instantly</li>
              <li>Effortlessly contribute your work to GitHub</li>
              <li>Streamline collaboration between Dev and QA teams</li>
              <li>Automate releases with confidence and consistency</li>
              <li>Introduce custom CI actions at any step of the process</li>
            </ul>
          </div>
          <br/>
          <p><strong>log in with your GitHub account</strong> to get started.</p>
        </div>
        
        <br/>
        <LoginButton/>
        <div class="footer">
          <a href="https://nimbasolutions.com">Nimba Solutions | Make it Easy.</a>
        </div>
        <br/> 
      </body>
    </div>
  );
};

export default Login;
